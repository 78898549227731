import { IHost } from "@Interfaces";
import { ITemplate } from "@Templates/ITemplate";
import { DEFAULT_PROFILE_PIC_SRC } from "repoV2/utils/common/assets/assets";
import { isBrowser, isEmpty } from "@Utils/common";
import { ROUTES, validUrl } from "@Utils/constants";

const dummyTeamMembersData: Array<IHost.ITeamMemberItem> = [1, 2, 3].map(i => ({
    occurrence_order: 0,
    member_name: `< Member Name >`,
    social_info: {
        staff_heading: `< Member Heading >`,
        staff_description: `< Member Description >`,
    },
    username: `username ${i}`,
}));

export const getTeamSectionProps = ({
    preview,
    host,
    handlePreviewPopup,
}: {
    preview: boolean;
    host: IHost.IStore;
    handlePreviewPopup: () => void;
}): ITemplate.ITeamSectionProps => {
    const teamMembersData = (
        host?.team?.data ||
        (preview && dummyTeamMembersData) ||
        []
    ).map(teamMember => ({
        username: teamMember.username,
        displayImage: teamMember?.display_image || DEFAULT_PROFILE_PIC_SRC,
        memberName: teamMember?.member_name || "",
        staffHeading: teamMember?.social_info?.staff_heading || "",
        staffDescription: teamMember?.social_info?.staff_description || "",
        socialInfo: (
            [
                "instagram",
                "facebook_name",
                "facebook",
                "twitter",
                "tiktok",
            ] as Array<ITemplate.ITeamMemberSocialKeys>
        )
            .filter(socialPlatform =>
                // @ts-ignore
                validUrl.test(teamMember?.social_info?.[socialPlatform] || "")
            )
            .map(socialPlatform => ({
                platform: socialPlatform,
                // @ts-ignore
                link: teamMember?.social_info?.[socialPlatform],
            })),
    }));
    const cardClick = (data: ITemplate.ITeamMember) => {
        const postRoute = `${ROUTES.TEAM_MEMBER.urlPath}/${data?.username}`;
        if (preview) {
            handlePreviewPopup();
        } else if (isBrowser()) {
            window.location.href = `${postRoute}`;
        }
    };

    return {
        hideComponent: isEmpty(teamMembersData),
        // @ts-ignore
        teamMembersData,
        title:
            host?.dataHost?.profile_data?.custom_team_section_title ||
            "Learn from the best",

        cardClick,
    };
};
