import { ITemplate } from "@Templates/ITemplate";
import { IHost } from "@Interfaces";
import { NextRouter } from "next/router";
import { IHTMLHeader } from "@Modules/common/HTMLHeader/HTMLHeader";
import { IUseModal } from "@Utils";
import { ITemplateKey } from "@Utils/constants";
import { INavbar } from "@Modules/common/Navbar/INavbar";
import { Dispatch } from "redux";
import { getHeaderProps } from "./headerProps";
import { getAboutSectionProps } from "./aboutSectionProps";
import { getEventSectionsProps } from "./eventSectionsProps";
import { getTestimonialsSectionProps } from "./testimonialsSectionProps";
import { getTeamSectionProps } from "./teamSectionProps";
import { getBlogPostsSectionProps } from "./blogPostsSectionProps";
import { getFooterSectionProps } from "./footerSectionProps";
import { getNavbarProps } from "./navbarProps";
import { getGallerySectionProps } from "./gallerySectionProps";
import { getTopFeaturesProps } from "./topFeaturesProps";
import { getStatsSectionProps } from "./statsSectionProps";

export const getHostPageProps = ({
    preview,
    host,
    handlePreviewPopup,
    postAnalyticalEvent,
    router,
    makeContextualHref,
    onGetInTouchClick,
    openModal,
    pauseTestimonialsAutoplay,
    setPauseTestimonialsAutoplay,
    template,
    onShareClick,
    pauseGalleryAutoplay,
    setPauseGalleryAutoplay,
    dispatch,
    hostName,
}: {
    preview: boolean;
    host: IHost.IStore;
    handlePreviewPopup: () => void;
    postAnalyticalEvent: (e: Object) => void;
    router: NextRouter;
    makeContextualHref: (extraQueryParams: { [key: string]: any }) => string;
    onGetInTouchClick: ITemplate.IOnGetInTouchClick;
    openModal: IUseModal.IReturnType["openModal"];
    pauseTestimonialsAutoplay: boolean;
    setPauseTestimonialsAutoplay: React.Dispatch<React.SetStateAction<boolean>>;
    template: ITemplateKey;
    onShareClick: ITemplate.IOnShareClick;
    pauseGalleryAutoplay: boolean;
    setPauseGalleryAutoplay: React.Dispatch<React.SetStateAction<boolean>>;
    dispatch: Dispatch<any>;
    hostName: string;
}) => {
    // Load the builder data if opened via creator tool > webpage details i.e template preview section
    const hostData = host?.dataHost?.profile_data;

    const headerProps: IHTMLHeader.IProps = getHeaderProps({
        hostData,
        hostName,
    });

    const navbarProps: INavbar.IProps = getNavbarProps({
        preview,
        hostData,
        handlePreviewPopup,
        onGetInTouchClick,
        template,
    });

    const aboutSectionProps: ITemplate.IAboutSectionProps =
        getAboutSectionProps({
            preview,
            host,
            onGetInTouchClick,
        });

    /**
     * event Sections Props: section"s" is used because in some templates,
     * there are multiple event sections, aka event categories.
     * (eg: modern, classic templates use event categories to display events)
     */
    const eventSectionsProps: ITemplate.IEventSectionsProps =
        getEventSectionsProps({
            preview,
            host,
            handlePreviewPopup,
            postAnalyticalEvent,
            router,
            makeContextualHref,
            onShareClick,
            onGetInTouchClick,
            dispatch,
        });

    const testimonialsSectionProps: ITemplate.ITestimonialsSectionProps =
        getTestimonialsSectionProps({
            preview,
            handlePreviewPopup,
            hostData,
            openModal,
            pauseTestimonialsAutoplay,
            setPauseTestimonialsAutoplay,
        });

    const gallerySectionProps: ITemplate.IGallerySectionProps =
        getGallerySectionProps({
            hostData,
            pauseGalleryAutoplay,
            setPauseGalleryAutoplay,
            openModal,
            postAnalyticalEvent,
            preview,
            handlePreviewPopup,
            onShareClick,
            template,
        });

    const teamSectionProps: ITemplate.ITeamSectionProps = getTeamSectionProps({
        preview,
        host,
        handlePreviewPopup,
    });

    const blogPostsSectionProps: ITemplate.IBlogPostsSectionProps =
        getBlogPostsSectionProps({
            preview,
            host,
            handlePreviewPopup,
        });

    const footerSectionProps: ITemplate.IFooterSectionProps =
        getFooterSectionProps({
            preview,
            hostData,
            handlePreviewPopup,
            template,
        });

    const topFeaturesProps: ITemplate.ITopFeaturesSectionProps =
        getTopFeaturesProps({ hostData, onGetInTouchClick, preview });

    const statsSectionProps: ITemplate.IStatsSectionProps =
        getStatsSectionProps({ hostData, preview });

    return {
        hostData,
        headerProps,
        navbarProps,
        aboutSectionProps,
        eventSectionsProps,
        testimonialsSectionProps,
        gallerySectionProps,
        teamSectionProps,
        blogPostsSectionProps,
        footerSectionProps,
        topFeaturesProps,
        statsSectionProps,
    };
};
