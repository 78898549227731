import React from "react";
import { htmlToText } from "html-to-text";
import { isBrowser } from "@Utils";
import { IHost } from "@Interfaces";
import { IHTMLHeader } from "@Modules/common/HTMLHeader/HTMLHeader";
import { initFbPixel } from "repoV2/utils/common/analytics/fbPixel/initFbPixel";

export const getHeaderProps = ({
    hostData,
}: {
    hostData?: IHost.IHostData;
    hostName: string;
}): IHTMLHeader.IProps => {
    const headerProps = {
        isEvent: false,
        title: hostData?.social_info?.hero_section_title || hostData?.name,
        favicon: hostData?.social_info?.favicon || hostData?.profile_pic,
        description:
            hostData?.short_description ||
            (hostData?.description && htmlToText(hostData?.description)) ||
            hostData?.social_info?.hero_section_title,
        image: hostData?.cover_pic,
        video: hostData?.intro_video,
        metaScript: hostData?.custom_meta_script,
        scriptTags: hostData?.custom_script_tags,
        additionalHeaders: () => {
            return (
                <>
                    {isBrowser() && hostData
                        ? initFbPixel({ id: hostData?.custom_pixel_token })
                        : null}
                </>
            );
        },
    };

    return headerProps;
};
