import { IHost } from "@Interfaces";
import { ITemplate } from "@Templates/ITemplate";
import { ITemplateKey, ROUTES } from "@Utils/constants";

export const getFooterSectionProps = ({
    preview,
    hostData,
    handlePreviewPopup,
    template,
}: {
    preview: boolean;
    hostData?: IHost.IHostData;
    handlePreviewPopup: () => void;
    template: ITemplateKey;
}): ITemplate.IFooterSectionProps => {
    const handleClick = (e: React.MouseEvent) => {
        if (preview) {
            e.preventDefault();
            handlePreviewPopup();
        }
    };
    return {
        tnCLink: hostData?.custom_t_c || ROUTES.COMMON_TERMS.urlPath,
        customFooterContent: hostData?.custom_footer_content,
        template,
        handleClick,
        overrideFooterDefaultText: hostData?.override_footer_default_text,
    };
};
