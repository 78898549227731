import { isEmpty } from "@Utils";
import { IHost } from "@Interfaces";
import { ITemplate } from "@Templates/ITemplate";
import { getGetInTouchCtaText, getHideGetInTouch } from "./common";

const topFeaturesDummyData: IHost.ICreatorTopFeature[] = [1, 2, 3].map(i => ({
    id: i,
    title: "<Feature Title>",
    description: "<Feature Description>",
    priority: i,
    media: `https://dummyimage.com/100x100/555/ffffff&text=+`,
}));

export const getTopFeaturesProps = ({
    hostData,
    onGetInTouchClick,
    preview,
}: {
    hostData?: IHost.IHostData;
    onGetInTouchClick: ITemplate.IOnGetInTouchClick;
    preview: boolean;
}): ITemplate.ITopFeaturesSectionProps => {
    return {
        hideComponent: isEmpty(hostData?.features?.data) && !preview,
        title: hostData?.features?.title,
        image: hostData?.social_info?.features_image,
        description: hostData?.social_info?.features_description,
        data:
            hostData?.features?.data?.length === 0 && preview
                ? topFeaturesDummyData
                : (hostData?.features?.data || []).sort(
                      (a, b) => b.priority - a.priority
                  ),
        hideGetInTouch: getHideGetInTouch(hostData),
        getInTouchCtaText: getGetInTouchCtaText(hostData),
        onGetInTouchClick,
        preview,
    };
};
