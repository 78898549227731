import { ITemplate } from "@Templates/ITemplate";
import { isEmpty } from "@Utils";
import { IHost } from "@Interfaces";
import { getSortedSocialLinks } from "@Utils/host";
import { getWordUsedForCourse } from "@Utils/content/utils";
import { getGetInTouchCtaText, getHideGetInTouch } from "./common";

export const getAboutSectionProps = ({
    preview,
    host,
    onGetInTouchClick,
}: {
    preview: boolean;
    host: IHost.IStore;
    onGetInTouchClick: ITemplate.IOnGetInTouchClick;
}): ITemplate.IAboutSectionProps => {
    // Load the builder data if opened via creator tool > webpage details i.e template preview section
    const hostData = host?.dataHost?.profile_data;

    const socialInfo = hostData?.social_info || {};

    const socialLinks: ITemplate.ISocialIconsProps["socialLinks"] =
        getSortedSocialLinks(socialInfo);

    return {
        preview,
        shortDescription:
            hostData?.short_description ||
            (preview ? "< Your short bio will be displayed here >" : undefined),
        profileImg: hostData?.profile_pic,
        title: hostData?.title,
        name:
            hostData?.name ||
            (preview ? "< Your name will be displayed here >" : undefined),
        hideSocialIcons: isEmpty(socialLinks),
        socialLinks,
        heroSectionTitle: hostData?.social_info?.hero_section_title,
        aboutSectionTitle: hostData?.social_info?.about_section_title,
        mobileCoverImage: hostData?.social_info?.mobile_cover_image,
        introVideo: hostData?.intro_video,
        coverImg: hostData?.cover_pic,
        coverImgType: hostData?.social_info?.cover_pic_type,
        hideGetInTouch: getHideGetInTouch(hostData),
        getInTouchCtaText: getGetInTouchCtaText(hostData),
        getInTouchHeaderText:
            hostData?.social_info?.interest_helper_text ||
            `Still have questions you’d like to ask us about the ${getWordUsedForCourse(
                hostData
            )}?`,
        onGetInTouchClick,
        experience: {
            years: hostData?.social_info?.experience_years_count,
            label: hostData?.social_info?.experience_years_label,
        },
        expandHostDesc: !!hostData?.expand_host_desc, // Whether the host description
        description:
            hostData?.description ||
            (preview
                ? "< Description about your page will be displayed here >"
                : undefined),
    };
};
