import { IHost } from "@Interfaces";
import { INavbar } from "@Modules/common/Navbar/INavbar";
import { ITemplateKey } from "@Utils/constants";

export const getNavbarProps = ({
    preview,
    hostData,
    handlePreviewPopup,
    onGetInTouchClick,
    template,
}: {
    preview: boolean;
    hostData?: IHost.IHostData;
    handlePreviewPopup: () => void;
    onGetInTouchClick: () => void;
    template: ITemplateKey;
}): INavbar.IBaseProps => {
    return {
        preview,
        hostData,
        handlePreviewPopup,
        onGetInTouchClick,
        template,
    };
};
