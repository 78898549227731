import { IHost } from "@Interfaces";
import { ITemplate } from "@Templates/ITemplate";
import { IUseModal, MODAL_TYPES, ITemplateKey, TEMPLATE_KEYS } from "@Utils";
import { isEmpty } from "@Utils/common";
import { GALLERY_TABS } from "@Utils/constants";

export const getGallerySectionProps = ({
    hostData,
    pauseGalleryAutoplay,
    setPauseGalleryAutoplay,
    openModal,
    postAnalyticalEvent,
    preview,
    handlePreviewPopup,
    onShareClick,
    template,
}: {
    hostData?: IHost.IHostData;
    pauseGalleryAutoplay: boolean;
    setPauseGalleryAutoplay: React.Dispatch<React.SetStateAction<boolean>>;
    openModal: IUseModal.IReturnType["openModal"];
    postAnalyticalEvent: (e: Object) => void;
    preview: boolean;
    handlePreviewPopup: () => void;
    onShareClick: ITemplate.IOnShareClick;
    template: ITemplateKey;
}): ITemplate.IGallerySectionProps => {
    const galleryData: ITemplate.IGallerySectionProps["galleryData"] = (
        hostData?.gallery?.data || []
    ).sort((a, b) => b.priority - a.priority);

    const onGalleryItemClick: ITemplate.IGallerySectionProps["onGalleryItemClick"] =
        (selectedMedia, galleryFilter = GALLERY_TABS.ALL.key) => {
            if (preview) {
                handlePreviewPopup();
                return;
            }

            setPauseGalleryAutoplay(true);

            postAnalyticalEvent({
                event_name: "tapPic",
                action: "Tap",
                ui_component: "Card",
            });

            openModal(MODAL_TYPES.ClassicGallery, {
                modalProps: {
                    selectedMedia,
                    galleryFilter,
                },
                overrides: {
                    handleClose: closeModal => {
                        closeModal();
                        setPauseGalleryAutoplay(false);
                    },
                },
            });
        };

    const onGalleryItemShareClick: ITemplate.IGallerySectionProps["onGalleryItemShareClick"] =
        item => {
            if (preview) {
                handlePreviewPopup();
                return;
            }
            postAnalyticalEvent({
                event_name: "sharePic",
                action: "Tap",
                ui_component: "Button",
            });
            onShareClick("gallery", {
                title: item.caption,
            });
        };

    return {
        hideComponent: isEmpty(galleryData) && !preview,
        title:
            hostData?.gallery?.title ||
            // Gallery is called Portfolio for all templates other than Classic
            (template === TEMPLATE_KEYS.CLASSIC ? "Gallery" : "Portfolio"),
        description:
            hostData?.gallery?.description ||
            hostData?.custom_headings?.gallery_description,
        galleryData,
        pauseGalleryAutoplay,
        onGalleryItemClick,
        onGalleryItemShareClick,
        preview,
    };
};
