import { ITemplate } from "@Templates/ITemplate";
import {
    EVENT_CATEGORISATION_TYPE,
    EVENT_LISTING_CARD_CTA,
    EVENT_TYPE,
    getAllEventCategories,
    getDummyEventListings,
    handlePrependPlans,
    isEmpty,
    planListingFormat,
} from "@Utils";
import { IHost } from "@Interfaces";
import { getExpressInterestCtaText } from "@Modules/eventPage/components/ExpressInterest/utils/expressInterest.helpers";
import { NextRouter } from "next/router";
import { isBrowser } from "@Utils/common";
import { fetchMiscellaneousData } from "@Modules/eventPage/components/MiscellaneousData/data/useFetchMiscellaneousData";
import { Dispatch } from "redux";
import { getWordUsedForCourse } from "@Utils/content/utils";
import { getListingSectionTitle } from "./common";

export const getEventSectionsProps = ({
    preview,
    host,
    handlePreviewPopup,
    postAnalyticalEvent,
    router,
    makeContextualHref,
    onShareClick: onShareClickProp,
    onGetInTouchClick,
    dispatch,
}: {
    preview: boolean;
    host: IHost.IStore;
    handlePreviewPopup: () => void;
    postAnalyticalEvent: (e: Object) => void;
    router: NextRouter;
    makeContextualHref: (extraQueryParams: { [key: string]: any }) => string;
    onShareClick: ITemplate.IOnShareClick;
    onGetInTouchClick: ITemplate.IOnGetInTouchClick;
    dispatch: Dispatch;
}): ITemplate.IEventSectionsProps => {
    const hostData = host?.dataHost?.profile_data;

    /**
     * extraCondition is used in case the listing card has other elements
     * such as share listing button.
     * `onClick` of these elements can trigger onEventListingClick as well
     */
    const onEventListingClick: ITemplate.IEventSectionsProps["onEventListingClick"] =
        ({
            uuid: listingUuid,
            plan_uuid: planUuid,
            url_slug: urlSlug,
        }: ITemplate.IEventListingData) => {
            if (preview) {
                handlePreviewPopup();
                return;
            }

            postAnalyticalEvent({
                event_name: "bookASpot",
                action: "Tap",
                ui_component: "Button",
            });
            let searchString = "";
            if (isBrowser()) {
                searchString = window.location.search;
            }

            const isPlanEvent = !isEmpty(planUuid);
            const eventUrlId = urlSlug || listingUuid;

            const planSlug = urlSlug;
            const planUrlId = planSlug || planUuid;

            let contextualRouteParams = {
                eventId: listingUuid,
            };
            let displayRoute = `/${eventUrlId}${searchString}`;

            if (isPlanEvent) {
                contextualRouteParams = {
                    ...contextualRouteParams,
                    ...(planUuid && { plan_id: planUuid }),
                    ...(planSlug && { planSlug }),
                };
                displayRoute = `/plan/${planUrlId}${searchString}`;
            }

            router.push(
                makeContextualHref(contextualRouteParams),
                displayRoute,
                { shallow: true }
            );

            fetchMiscellaneousData({ listingUuid, dispatch });
        };

    const plansData = host?.plans;
    const plans = (plansData?.list || []).map(item =>
        planListingFormat(item, plansData?.title)
    );

    let eventListings: Array<ITemplate.IEventListingData> = handlePrependPlans(
        hostData?.listings || [],
        plans,
        hostData?.prepend_plans
    ).map((eventListing: ITemplate.IEventListingData) => {
        const expressInterestCtaText = getExpressInterestCtaText({
            eventStatus: eventListing.status as number,
            hostData: hostData as IHost.IHostData,
        });
        return {
            ...eventListing,
            key: `${eventListing.uuid}--${eventListing?.plan_uuid || ""}`,
            hidePrice: eventListing?.metadata?.hide_price || false,
            ctaText:
                (eventListing?.metadata?.booking_cta_text as string) ||
                ("is_payment_disabled" in eventListing &&
                    eventListing?.is_payment_disabled &&
                    expressInterestCtaText) ||
                (eventListing.type === EVENT_TYPE.BRANDED_COMMUNITY &&
                    EVENT_LISTING_CARD_CTA.SUBSCRIBE_NOW) ||
                ([EVENT_TYPE.RECORDED_CONTENT, EVENT_TYPE.MERCHANDISE].includes(
                    eventListing.type
                )
                    ? EVENT_LISTING_CARD_CTA.BUY_NOW
                    : EVENT_LISTING_CARD_CTA.BOOK_A_SPOT),
        };
    });

    if (preview && isEmpty(eventListings)) {
        eventListings = getDummyEventListings();
    }
    const onShareClick: ITemplate.IEventSectionsProps["onShareClick"] = (
        data: ITemplate.IOnShareClickDataParam
    ) => {
        if (!preview) {
            onShareClickProp("event", data);
            postAnalyticalEvent({
                event_name: "share",
                action: "Tap",
                ui_component: "Button",
            });
        } else {
            handlePreviewPopup();
        }
    };

    const onHeartClick = (listingId?: number) => {
        if (!preview) {
            postAnalyticalEvent({
                event_name: "shownInterest",
                action: "Tap",
                ui_component: "Button",
            });
            onGetInTouchClick(true, listingId);
        } else {
            handlePreviewPopup();
        }
    };

    const showSkuLabelAsTag: boolean = !!(
        hostData?.categorise_type &&
        [
            EVENT_CATEGORISATION_TYPE.KEYWORDS_SKU_TYPE,
            EVENT_CATEGORISATION_TYPE.NO_CATEGORISATION,
        ].includes(hostData?.categorise_type) &&
        !!hostData?.categorise_by_keyword
    );

    const categoriseType =
        hostData?.categorise_type ||
        EVENT_CATEGORISATION_TYPE.NO_CATEGORISATION;

    return {
        sectionTitle: getListingSectionTitle({
            templateName: host?.template!,
            customHeader: hostData?.custom_listings_header,
            wordUsedForCourse: getWordUsedForCourse(hostData),
        }),
        eventListings,
        onEventListingClick,
        allKeywords: hostData?.all_keywords || [],
        allEventCategories: getAllEventCategories(
            hostData,
            plansData?.title,
            categoriseType
        ),
        categoriseType,
        showSkuLabelAsTag,
        onShareClick,
        onHeartClick,
        customTruncateExpandLabels: hostData?.custom_truncate_expand_label,
        template: host?.template!,
    };
};
