import { isEmpty } from "@Utils";
import { IHost } from "@Interfaces";
import { ITemplate } from "@Templates/ITemplate";

const statsDummyData: IHost.ICreatorStat[] = [1, 2, 3].map(i => ({
    id: i,
    value: `<Title>`,
    description: "<Description>",
    priority: i,
}));

export const getStatsSectionProps = ({
    hostData,
    preview,
}: {
    hostData?: IHost.IHostData;
    preview: boolean;
}): ITemplate.IStatsSectionProps => {
    return {
        hideComponent: isEmpty(hostData?.statistics?.data) && !preview,
        title: hostData?.statistics?.title,
        cover_img: hostData?.cover_pic,
        data:
            hostData?.statistics?.data?.length === 0 && preview
                ? statsDummyData
                : (hostData?.statistics?.data || []).sort(
                      (a, b) => b.priority - a.priority
                  ),
        preview,
        backgroundImg: hostData?.social_info?.statistics_background_image,
    };
};
