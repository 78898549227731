import { IHost } from "@Interfaces";
import { ITemplate } from "@Templates/ITemplate";
import { isEmpty } from "@Utils/common";

export const getBlogPostsSectionProps = ({
    preview,
    host,
    handlePreviewPopup,
}: {
    preview: boolean;
    host: IHost.IStore;
    handlePreviewPopup: () => void;
}): ITemplate.IBlogPostsSectionProps => {
    const blogPosts = (host?.blog?.data || []).slice(0, 3);

    return {
        hideComponent: isEmpty(blogPosts),
        title: "Recent Blog Posts",
        blogPosts,
        preview,
        handlePreviewPopup,
    };
};
