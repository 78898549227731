import { IUseModal, MODAL_TYPES, TESTIMONIAL_DATA_TYPE } from "@Utils";
import { DEFAULT_PROFILE_PIC_SRC } from "repoV2/utils/common/assets/assets";
import { IHost } from "@Interfaces";
import { ITemplate } from "@Templates/ITemplate";
import { isEmpty } from "@Utils/common";

const dummyTestimonialData: IHost.ITestimonialItem[] = [1, 2, 3].map(i => ({
    id: i,
    creator: i,
    name: "<Reviewer Name>",
    content: "<Your testimonials will be displayed here>",
    cover_image: "",
    uuid: `${i}`,
    type: TESTIMONIAL_DATA_TYPE.TEXT,
    media: "",
    priority: i,
}));

export const getTestimonialsSectionProps = ({
    preview,
    handlePreviewPopup,
    hostData,
    openModal,
    pauseTestimonialsAutoplay,
    setPauseTestimonialsAutoplay,
}: {
    preview: boolean;
    hostData?: IHost.IHostData;
    handlePreviewPopup: () => void;
    openModal: IUseModal.IReturnType["openModal"];
    pauseTestimonialsAutoplay: boolean;
    setPauseTestimonialsAutoplay: React.Dispatch<React.SetStateAction<boolean>>;
}): ITemplate.ITestimonialsSectionProps => {
    const onTestimonialClick: ITemplate.ITestimonialsSectionProps["onTestimonialClick"] =
        (e, testimonialItem): void => {
            e?.preventDefault();
            if (preview) {
                handlePreviewPopup();
            } else {
                setPauseTestimonialsAutoplay(true);
                openModal(MODAL_TYPES.ModernTestimonial, {
                    modalProps: {
                        selectedMedia: testimonialItem,
                    },
                    overrides: {
                        handleClose: closeModal => {
                            closeModal();
                            setPauseTestimonialsAutoplay(false);
                        },
                    },
                });
            }
        };

    const testimonialsData = (
        (hostData?.testimonials?.data?.length &&
            hostData?.testimonials?.data?.sort(
                (a, b) => (b?.priority ?? 0) - (a?.priority ?? 0)
            )) ||
        (preview ? dummyTestimonialData : []) ||
        []
    ).map(item => ({
        ...item,
        cover_image: item.cover_image || DEFAULT_PROFILE_PIC_SRC,
        name: item.name || "",
    }));

    return {
        hideComponent: isEmpty(testimonialsData),
        title: hostData?.testimonials?.title || "",
        testimonialsData,
        onTestimonialClick,
        pauseTestimonialsAutoplay,
        testimonialsSubtitle: hostData?.social_info?.testimonials_subtitle,
        testimonialsDescription:
            hostData?.social_info?.testimonials_description,
    };
};
